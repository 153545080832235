import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Reassurance from '../components/Reassurance'
import JoinUs from '../components/JoinUs'

const PartnerPage = ({ name, hero, reassurance, pageBottom }) => {
  return (
    <>
      <Hero {...hero} />
      <Reassurance partnerName={name} subTitle={reassurance.subTitle} args={reassurance.arguments} />
      <JoinUs
        title={pageBottom.title}
        subTitle={pageBottom.subTitle}
        link={`${process.env.GATSBY_APP_URL}/signup?pType=FREELANCE`}
        linkText="J'en profite"
        ownBg
      />
    </>
  )
}

const PartnerTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout transparentHeader isFree>
      <Helmet>
        <title>Artelink x {frontmatter.name}</title>
        {/* <meta name="description" content={`Artelink x ${frontmatter.name}`} /> */}
        {/* Open Graph */}
        {/* <meta name="og:title" content={`Artelink x ${frontmatter.name} : le partenariat qui va faciliter votre vie de freelance`} /> */}
        {/* <meta name="og:description" content={`Artelink s'associe à ${frontmatter.name} pour vous faciliter votre aventure de freelance.`} /> */}
      </Helmet>

      <PartnerPage {...frontmatter} />
    </Layout>
  )
}

PartnerTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PartnerTemplate

export const pageQuery = graphql`
  query PartnerByName($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        hero {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
          subTitle
        }
        reassurance {
          subTitle
          arguments {
            icon {
              publicURL
            }
            title
            description
          }
        }
        pageBottom {
          title
          subTitle
        }
      }
    }
  }
`
