import React from 'react'

import ContentWrapper from '../ContentWrapper'
import Step from '../Step'

import * as s from './style.module.sass'

const Reassurance = ({ partnerName, subTitle, args }) => (
  <ContentWrapper>
    <div className={s.reassurance}>
      <h1>Artelink x <strong>{partnerName}</strong></h1>
      <p className={s.subTitle}>{subTitle}</p>

      <div className={s.args}>
        {
          args.map((arg) => (
            <Step key={arg.title} title={arg.title} message={arg.description} icon={arg.icon} />
          ))
        }
      </div>
    </div>
  </ContentWrapper>
)

export default Reassurance
